// Chakra imports
import {
  Flex,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
  //   useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";

import "./map.css";
import React, { useEffect, useState } from "react";
import GoogleMap from "google-map-react";
import axios from "axios";
import useAuth from "views/Pages/useAuth";
import ScanModal from "components/modals/scanModal";
import MapMarker from "components/MapMarker";

import { countriesCoordinates } from "variables/map";
function ScansDashboard(props) {
  const toast = useToast();

  const showToast = ({ status, title, description }) => {
    toast({
      title: title,
      description: description,
      status: status,
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });
  };

  const textColor = useColorModeValue("gray.700", "white");
  const [showLocationInfo, setInfo] = useState(null);
  const [countries, setCountries] = useState([]);
  const [scans, setScans] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { company } = useAuth();
  const [currentZoom, setCurrentZoom] = useState(2);
  const [currentCenter, setCurrentCenter] = useState([0, 0]);
  const [tagStatus, setStatusTag] = useState({});
  function handleClickInfo(index) {
    if (index == showLocationInfo) {
      setInfo(null);
    } else {
      setInfo(index);
    }
  }

  function changeMapInfo(e) {
    setCurrentZoom(e.zoom);
  }

  function fetchCountryScans(index) {
    setCurrentZoom(6);
    setCurrentCenter(countries[index].coordinates);
  }

  function openCityScansModal(e, city, country) {
    setSelectedCity(city);
    setSelectedCountry(country);
    onOpen();
  }

  function getCountriesCoordinates() {
    return countriesCoordinates;
  }

  function setCountriesData(names) {
    const coords = getCountriesCoordinates();
    const rawData = names.map((item) => {
      return {
        name: item,
        coordinates: coords.find((coord) => coord.name == item).coordinates,
      };
    });

    setCountries(rawData);
  }

  useEffect(() => {
    axios
      .get(
        `/scan?filter=true&companyid=A002&country=${props.country}&time=${props.timeRange}&distributor=${props.distributor}&product=${props.product}`
      )
      .then((res) => {
        
        const countryNames = Array.from(
          new Set(res.data.map((item) => item.location.country))
        );
        console.log(countryNames)
        setCountriesData(countryNames);
        setScans(res.data);
      })
      .catch((e) => {
        showToast({
          status: "error",
          title: "Server error",
          description: "Something went wrong while fetching scans",
        });
        console.log(e)
      });
  }, [props.timeRange, props.country, props.distributor, props.product]);

  return (
    <Flex>
      <Card overflowX="scroll" pb="22px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Products Scan Summary
          </Text>
        </CardHeader>

        <CardBody>
          <div>
            <div className="google-map">
              {scans.length > 0 ? (
                <GoogleMap
                  defaultCenter={[
                    scans[0].location.latitude,
                    scans[0].location.longitude,
                  ]}
                  center={currentCenter}
                  zoom={currentZoom}
                  onChange={(e) => changeMapInfo(e)}
                >
                  {scans.length > 0 &&
                    currentZoom < 6 &&
                    countries.map((item, index) => (
                      <MapMarker
                        key={index}
                        lat={item.coordinates[0]}
                        lng={item.coordinates[1]}
                        indexT={index}
                        showLocationInfo={showLocationInfo}
                        handleClickInfo={() => fetchCountryScans(index)}
                        suspicious={
                          item.flag == 0 || item.flag == undefined
                            ? "#ff3300"
                            : item.flag == 2
                            ? "gray"
                            : "yellow"
                        }
                      />
                    ))}
                  {currentZoom >= 6 &&
                    scans.map((item, index) => (
                      <MapMarker
                        key={index}
                        lat={item.location.latitude}
                        lng={item.location.longitude}
                        text={item.productId}
                        city={item.location.city}
                        totalScans={
                          scans.filter(
                            (x) =>
                              x.location.city == item.location.city &&
                              item.location.country == x.location.country
                          ).length
                        }
                        indexT={index}
                        showLocationInfo={showLocationInfo}
                        handleClickInfo={() => handleClickInfo(index)}
                        openScansModal={(e) =>
                          openCityScansModal(
                            e,
                            item.location.city,
                            item.location.country
                          )
                        }
                        suspicious={
                          item.flag == 0 || item.flag == undefined
                            ? "#ff3300"
                            : item.flag == 2
                            ? "gray"
                            : "yellow"
                        }
                      />
                    ))}
                </GoogleMap>
              ) : (
                <GoogleMap
                  center={currentCenter}
                  zoom={currentZoom}
                  onChange={(e) => changeMapInfo(e)}
                ></GoogleMap>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
      {isOpen && (
        <ScanModal
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          selectedCity={selectedCity}
          data={scans.filter(
            (x) =>
              x.location.city == selectedCity &&
              x.location.country == selectedCountry
          )}
          suspiciousTags={tagStatus}
        />
      )}
    </Flex>
  );
}

export default ScansDashboard;
